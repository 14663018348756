'use client'

import { useState, useEffect, useRef } from 'react'
import { motion, useScroll, useSpring, useInView, AnimatePresence } from 'framer-motion'
import { Canvas, useFrame } from '@react-three/fiber'
import { Sphere, MeshDistortMaterial } from '@react-three/drei'
import { Zap, Palette, BarChart, Menu, Clock, ThumbsUp, DollarSign, HelpCircle, BrainIcon, BarChartIcon, BrainCircuitIcon, BrainCogIcon } from 'lucide-react'
import type { Mesh } from 'three'
import { Vector3 } from 'three'

const AnimatedSphere = () => {
  const meshRef = useRef<Mesh>(null)

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.x = Math.sin(state.clock.getElapsedTime() * 0.2) * 0.2
      meshRef.current.rotation.y = state.clock.getElapsedTime() * 0.2
      meshRef.current.position.y = Math.sin(state.clock.getElapsedTime() * 0.4) * 0.3
      const pulseScale = 2.0 + Math.sin(state.clock.getElapsedTime() * 0.8) * 0.05
      meshRef.current.scale.lerp(new Vector3(pulseScale, pulseScale, pulseScale), 0.05)
    }
  })

  return (
    <Sphere 
      ref={meshRef} 
      args={[1, 64, 64]} 
      scale={2.0}
    >
      <MeshDistortMaterial
        color="#fff1f6"
        attach="material"
        distort={0.3}
        speed={3}
        roughness={0.05}
        metalness={0.8}
        transparent={true}
        opacity={0.6}
        clearcoat={1}
        clearcoatRoughness={0.1}
      />
    </Sphere>
  )
}

const ContactSphere = () => {
  const meshRef = useRef<Mesh>(null)
  const [hovered, setHovered] = useState(false)

  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.x = Math.sin(state.clock.getElapsedTime() * 0.4) * 0.3
      meshRef.current.rotation.y = state.clock.getElapsedTime() * 0.5
      meshRef.current.position.y = Math.sin(state.clock.getElapsedTime()) * 0.5
      const scale = hovered ? 2.4 : 2.0
      meshRef.current.scale.lerp(new Vector3(scale, scale, scale), 0.2)
    }
  })

  return (
    <Sphere 
      ref={meshRef} 
      args={[1, 64, 64]} 
      scale={2.0}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      <MeshDistortMaterial
        color="#fff1f6"
        attach="material"
        distort={hovered ? 0.6 : 0.4}
        speed={8}
        roughness={0.05}
        metalness={0.8}
        transparent={true}
        opacity={0.6}
        clearcoat={1}
        clearcoatRoughness={0.1}
      />
    </Sphere>
  )
}

const TextReveal = ({ children }: { children: React.ReactNode }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, staggerChildren: 0.1 }}
    >
      {children}
    </motion.div>
  )
}

export default function Component() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const heroRef = useRef(null)
  const servicesRef = useRef(null)
  const portfolioRef = useRef(null)
  const qaRef = useRef(null)
  const ctaRef = useRef(null)

  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setCursorPosition({ x: e.clientX, y: e.clientY })
    }
    
    // Check if window is defined before adding the event listener
    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', handleMouseMove)
      
      // Cleanup function
      return () => window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const heroInView = useInView(heroRef, { once: false })
  const servicesInView = useInView(servicesRef, { once: false })
  const portfolioInView = useInView(portfolioRef, { once: false })
  const qaInView = useInView(qaRef, { once: false })
  const ctaInView = useInView(ctaRef, { once: false })

  return (
    <div className="relative min-h-screen bg-black text-white overflow-hidden">
      <style>{`
        .shine-effect {
          position: relative;
          overflow: hidden;
        }
        
        .shine-effect::after {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 50%;
          height: 100%;
          background: linear-gradient(
            120deg,
            transparent,
            rgba(255,255,255,0.6),
            transparent
          );
          animation: shine 3s infinite;
        }
        
        @keyframes shine {
          0% { left: -100%; }
          20% { left: 100%; }
          100% { left: 100%; }
        }
        
        .shine-text {
          background: linear-gradient(
            to right,
            #f35acb 20%,
            #ffffff 30%,
            #ffffff 70%,
            #f35acb 80%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          background-size: 200% auto;
          animation: shine 15s linear infinite;
        }
        
        @keyframes shine {
          0% {
            background-position: 200% center;
          }
          100% {
            background-position: -200% center;
          }
        }

        .portfolio-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2rem;
          padding: 2rem;
        }

        .portfolio-card {
          display: block;
          text-decoration: none;
          color: inherit;
          background: #fff;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 2px 8px rgba(0,0,0,0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .portfolio-card:hover {
          transform: translateY(-4px);
          box-shadow: 0 4px 12px rgba(0,0,0,0.15);
        }

        .portfolio-card img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }

        .portfolio-card h3 {
          padding: 1rem;
          margin: 0;
          font-size: 1.2rem;
          text-align: center;
        }
      `}</style>
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 z-50"
        style={{ scaleX }}
      />
      <motion.div
        className="hidden lg:block fixed w-12 h-12 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 mix-blend-screen pointer-events-none z-50 blur-sm"
        animate={{
          x: cursorPosition.x - 24,
          y: cursorPosition.y - 24,
          scale: 1.5,
        }}
        transition={{
          type: "tween",
          ease: "backOut",
          duration: 0.2
        }}
      />
      <header className="fixed top-0 left-0 right-0 z-40 bg-black bg-opacity-50 backdrop-blur-md">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-2xl font-bold">DesignAura.Agency</h1>
          </motion.div>
          <motion.button
            className="lg:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Menu size={24} />
          </motion.button>
          <motion.ul
            className="hidden lg:flex space-x-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, staggerChildren: 0.1 }}
          >
            {['Services', 'Portfolio', 'Q&A', 'Contact'].map((item) => (
              <motion.li key={item} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <a
                  href={`#${item.toLowerCase()}`}
                  className="hover:text-pink-500 transition-colors"
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById(item.toLowerCase());
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  {item}
                </a>
              </motion.li>
            ))}
          </motion.ul>
        </nav>
      </header>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="fixed inset-0 bg-black z-50 lg:hidden"
            initial={{ opacity: 0, x: '100%' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <div className="flex flex-col items-center justify-center h-full space-y-8">
              {['Services', 'Portfolio', 'Q&A', 'Contact'].map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase()}`}
                  className="text-2xl font-bold hover:text-pink-500 transition-colors"
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById(item.toLowerCase());
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                    setIsMenuOpen(false);
                  }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item}
                </motion.a>
              ))}
            </div>
            <motion.button
              className="absolute top-4 right-4"
              onClick={() => setIsMenuOpen(false)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>

      <main>
        <section ref={heroRef} className="relative min-h-screen flex items-center justify-center overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-900 via-black to-pink-900" />
          <div className="absolute inset-0">
            <Canvas camera={{ position: [0, 0, 6], fov: 75 }}>
              <ambientLight intensity={1.4} />
              <pointLight position={[10, 10, 10]} intensity={2.0} color="#ff80bf" />
              <pointLight position={[-10, -10, -10]} intensity={1.0} color="#ffffff" />
              <AnimatedSphere />
            </Canvas>
          </div>
          <div className="container mx-auto px-6 text-center relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 50 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="mb-8 relative"
            >
              <h2 className="text-5xl md:text-7xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 shine-text">
              Turn Visitors Into Customers
              </h2>
              <div className="h-1 w-24 bg-gradient-to-r from-purple-400 to-pink-600 mx-auto rounded-full"></div>
            </motion.div>
            <TextReveal>
              <motion.p
                className="text-xl md:text-2xl mb-12 max-w-2xl mx-auto"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 50 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                We're conversion assassins who transform underperforming websites into customer-getting machines. Our designs don't just turn heads - they turn visitors into revenue!
              </motion.p>
            </TextReveal>
            <motion.div
              className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : 50 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <motion.button
                className="bg-gradient-to-r from-purple-500 to-pink-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105 shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  const servicesSection = document.getElementById('services');
                  if (servicesSection) {
                    servicesSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                Fix My Conversions
              </motion.button>
              <motion.button
                className="bg-transparent border-2 border-white text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-white hover:text-purple-900 transition-all duration-300 transform hover:scale-105 shadow-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  const portfolioSection = document.getElementById('portfolio');
                  if (portfolioSection) {
                    portfolioSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                See the Magic
              </motion.button>
            </motion.div>
          </div>
          <div className="absolute bottom-10 left-0 right-0 flex justify-center">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: heroInView ? 1 : 0, y: heroInView ? 0 : -20 }}
              transition={{ duration: 0.8, delay: 0.8, repeat: Infinity, repeatType: 'reverse' }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </motion.div>
          </div>
        </section>

        <section ref={servicesRef} id="services" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-4xl font-bold mb-12 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: servicesInView ? 1 : 0, y: servicesInView ? 0 : 50 }}
              transition={{ duration: 0.8 }}
            >
              Our Services
            </motion.h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {[
                { icon: Zap, title: '⚡ Speed Optimization', description: 'When seconds cost sales, we deliver millisecond magic. Your site will load before competitors finish blinking.' },
                { icon: Palette, title: '🎨 Stunning Design', description: 'Beyond beautiful - we create interfaces that seduce visitors into customers. Every pixel has a purpose.' },
                { icon: BrainCogIcon, title: '🧠 Conversion Psychology', description: 'We dont guess, we engineer success. Our proven frameworks turn casual clicks into confident conversions.' },
              ].map((service, index) => (
                <motion.div
                  key={service.title}
                  className="bg-gray-800 p-8 rounded-lg text-center"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: servicesInView ? 1 : 0, y: servicesInView ? 0 : 50 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  whileHover={{ scale: 1.05, rotateY: 10 }}
                >
                  <service.icon className="mx-auto mb-4 text-pink-500" size={48} />
                  <h4 className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">{service.title}</h4>
                  <p>{service.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section ref={portfolioRef} id="portfolio" className="py-20 bg-black">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-4xl font-bold mb-12 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: portfolioInView ? 1 : 0, y: portfolioInView ? 0 : 50 }}
              transition={{ duration: 0.8 }}
            >
            Transformations We’re Proud Of
            </motion.h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                <motion.a
                  key={item}
                  href={
                    item === 1 ? "https://www.aesthetics-dent.com.ua/" :
                    item === 2 ? "https://www.bedfordmarketing.co/" :
                    item === 3 ? "https://www.cheddiescrackers.com/" :
                    item === 4 ? "https://www.vekstloop.no/" :
                    item === 5 ? "https://www.nuco.cloud/" :
                    item === 6 ? "https://www.thevintageseeker.ca/" :
                    item === 7 ? "https://pearpop.com/" :
                    item === 8 ? "https://www.sparkadvisors.com/" :
                    "https://www.zozh-brows.com/"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative overflow-hidden rounded-lg aspect-video cursor-pointer block"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: portfolioInView ? 1 : 0, scale: portfolioInView ? 1 : 0.8 }}
                  transition={{ duration: 0.5, delay: item * 0.1 }}
                  whileHover={{ 
                    scale: 1.03,
                    rotateY: 5,
                    transition: { duration: 0.2 }
                  }}
                >
                  <img
                    src={
                      item === 1 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559804483.png" :
                      item === 2 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559829302.png" :
                      item === 3 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559842466.png" :
                      item === 4 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559850005.png" :
                      item === 5 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559858169.png" :
                      item === 6 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559866013.png" :
                      item === 7 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559882752.png" :
                      item === 8 ? "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559897464.png" :
                      "https://raw.githubusercontent.com/xLX14/portImages/refs/heads/main/images/screenshot-1730559904839.png"
                    }
                    alt={`Portfolio item ${item}`}
                    className="w-full h-full object-cover"
                  />
                  <motion.div
                    className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex items-center justify-center text-center p-6"
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div>
                      <h4 className="text-xl font-bold mb-2">
                        {item === 1 ? "Smile Confidence Catalyst" :
                         item === 2 ? "E-commerce Growth Machine" :
                         item === 3 ? "Snack Revolution Hub" :
                         item === 4 ? "Lead Generation Powerhouse" :
                         item === 5 ? "Tech Evolution Gateway" :
                         item === 6 ? "Nostalgia Commerce Engine" :
                         item === 7 ? "Creator Empire Builder" :
                         item === 8 ? "Insurance Empire Accelerator" :
                         "Beauty Mastery Academy"}
                      </h4>
                      <p className="text-sm">
                        {item === 1 ? "Turned dental anxiety into 'I can't wait' - bookings surged 200%" :
                         item === 2 ? "Where 'just browsing' becomes 'shut up and take my money'" :
                         item === 3 ? "Converted casual snackers into passionate cheese enthusiasts" :
                         item === 4 ? "Doubled qualified leads with crystal-clear value proposition" :
                         item === 5 ? "Made cutting-edge tech feel like a must-have - demos up 150%" :
                         item === 6 ? "Turned vintage browsers into buyers within 30 seconds" :
                         item === 7 ? "Made creator partnerships irresistible - sign-ups up 200%" :
                         item === 8 ? "Transformed insurance hustlers into industry titans - agent growth up 170%" :
                         "Turned curious practitioners into elite brow artists - student success rate 195%"}
                      </p>
                    </div>
                  </motion.div>
                </motion.a>
              ))}
            </div>
          </div>
        </section>

        <section ref={qaRef} id="q&a" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <motion.h3
              className="text-4xl font-bold mb-12 text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: qaInView ? 1 : 0, y: qaInView ? 0 : 50 }}
              transition={{ duration: 0.8 }}
            >
              Q&A
            </motion.h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                { 
                  icon: DollarSign, 
                  question: "How can your prices be this good?", 
                  answer: "While other agencies waste money on fancy offices and expensive coffee runs, our elite team of conversion specialists operates remotely from four different countries. You get Silicon Valley-level talent at prices that won't give you a heart attack. Pretty sweet deal, right?" 
                },
                { 
                  icon: ThumbsUp, 
                  question: "Why should I pick you to rescue my website?",   
                  answer: "Because while your website's busy ghosting customers, we're busy turning 'just browsing' into 'just bought.' Our global squad of conversion ninjas specializes in one thing: making small business websites sell like crazy. While other agencies play artsy-fartsy with fonts, we're engineering your site to be a customer-grabbing machine." 
                },
                { 
                  icon: Clock, 
                  question: "How fast can you turn this ship around?", 
                  answer: "Most projects are completed within 2-4 weeks, depending on complexity. We know deadlines matter, so we work hard to deliver on time—if not sooner. Time is money, and we hate wasting either." 
                },
                { 
                  icon: HelpCircle, 
                  question: "What if I'm not satisfied over the results?", 
                  answer: "Your satisfaction is everything to us. We offer unlimited revisions to ensure you’re 100% happy with the final product. When you’re with us, your vision and satisfaction come first." 
                },
              ].map((item, index) => (
                <motion.div
                  key={item.question}
                  className="bg-gray-800 p-6 rounded-lg"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: qaInView ? 1 : 0, y: qaInView ? 0 : 50 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  whileHover={{ 
                    scale: 1.02,
                    transition: { duration: 0.2 }
                  }}
                >
                  <div className="flex items-center mb-4">
                    <item.icon className="text-pink-500 mr-2" size={24} />
                    <h4 className="text-xl font-bold">{item.question}</h4>
                  </div>
                  <p>{item.answer}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section ref={ctaRef} id="contact" className="py-20 bg-gradient-to-r from-purple-900 to-pink-900 relative overflow-hidden">
          <Canvas className="absolute inset-0" camera={{ position: [0, 0, 5], fov: 75 }}>
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <ContactSphere />
          </Canvas>
          <div className="container mx-auto px-6 text-center relative z-10">
            <motion.h3
              className="text-4xl font-bold mb-6"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: ctaInView ? 1 : 0, y: ctaInView ? 0 : 50 }}
              transition={{ duration: 0.8 }}
            >
              Ready to Supercharge Your Website?
            </motion.h3>
            <motion.p
              className="text-xl mb-12"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: ctaInView ? 1 : 0, y: ctaInView ? 0 : 50 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              Let’s turn it into a customer magnet that works 24/7.
            </motion.p>
            <motion.button
              className="bg-white text-purple-900 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition-all duration-300 transform hover:scale-105"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              animate={{ opacity: ctaInView ? 1 : 0, y: ctaInView ? 0 : 50 }}
              transition={{ duration: 0.3}}
              onClick={() => window.location.href = 'mailto:info@DesignAura.Agency'}
            >
             Let’s Chat!
            </motion.button>
            <p className="text-lg text-white mt-4">
              Or email us at: <a href="mailto:info@DesignAura.Agency" className="text-pink-500">Info@DesignAura.Agency</a>
            </p>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 py-8 relative overflow-hidden">
        <motion.div 
          className="absolute w-32 h-32 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 opacity-50 blur-xl"
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 180, 360],
            x: [0, 100, 0],
            y: [0, -50, 0],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="container mx-auto px-6 text-center relative z-10">
          <p>&copy; 2024 DesignAura.Agency. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}